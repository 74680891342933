import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Welcome from "./GovFormComponents/Welcome";
import QuestionFormStart from "./GovFormComponents/QuestionFormStart";
import QuestionsModal from "./GovFormComponents/QuestionsModal";

export default function GovForm() {
  //! states for conditional redering of components 
  const [showWelcome, setShowWelcome] = useState(true);
  const [showQuestionsModalForm, setShowQuestionsModalForm] = useState(false);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "rgb(243,243,254)",
          height: "100vh",
        }}
      >
        {/*//**   header */}
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-start",
            gap: "30px",
            alignContent: "center",
            alignItems: "center",
            padding: "30px 10px 0px 40px",
            width: "100%",
            height: "20%",
            visibility: showQuestionsModalForm ? "visible" : "hidden",
          }}
        >
          <Box
            sx={{
              width: "100px",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <svg
              data-name="Jotform Logo"
              viewBox="0 0 90 90"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.4843 83.2998C29.0901 84.8565 27.9553 87.5235 25.6752 87.5235H11.4056C8.59017 87.5235 6.29932 85.3027 6.29932 82.5734V68.7401C6.29932 66.5297 9.05048 65.4297 10.6562 66.9863L27.4843 83.2998Z"
                fill="white"
              />
              <path
                d="M49.7895 84.3767C45.5656 80.1804 45.5656 73.3768 49.7895 69.1805L65.0334 54.0359C69.2572 49.8396 76.1055 49.8396 80.3293 54.0359C84.5532 58.2323 84.5532 65.0359 80.3293 69.2322L65.0854 84.3768C60.8616 88.5731 54.0133 88.5731 49.7895 84.3767Z"
                fill="white"
              />
              <path
                d="M9.51946 46.3227C5.2956 42.1264 5.2956 35.3228 9.51946 31.1264L35.1733 5.62186C39.3972 1.42553 46.2454 1.42552 50.4693 5.62186C54.6932 9.8182 54.6932 16.6218 50.4693 20.8181L24.8154 46.3227C20.5916 50.5191 13.7433 50.5191 9.51946 46.3227Z"
                fill="white"
              />
              <path
                d="M30.4902 64.5004C26.2663 60.3041 26.2663 53.5005 30.4902 49.3042L65.1532 14.867C69.3771 10.6706 76.2253 10.6706 80.4492 14.867C84.673 19.0633 84.673 25.8669 80.4492 30.0633L45.7861 64.5004C41.5623 68.6968 34.714 68.6968 30.4902 64.5004Z"
                fill="white"
              />
            </svg>
          </Box>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              opacity: 1,
              fontWeight: "bold",
              // marginLeft: "10%",
              fontSize: "2em",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex:1,
              textAlign:'center',
            }}
          >
           Government Support Form Application
          </Typography>
        </Box>
        {showWelcome ? (
          <Welcome setShowWelcome={setShowWelcome} />
        ) : (
          <QuestionFormStart
            setShowQuestionsModalForm={setShowQuestionsModalForm}
            showQuestionsModalForm={showQuestionsModalForm}
          />
        )}
        {showQuestionsModalForm && (
          <QuestionsModal
            showQuestionsModalForm={showQuestionsModalForm}
            setShowQuestionsModalForm={setShowQuestionsModalForm}
          />
        )}
      </Box>
    </>
  );
}
