import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { updateFormState } from "../../Redux/formSlice";
import { useNavigate } from "react-router-dom";
// const steps = [
//   {
//     label: "Step 1",
//     fields: ["What is your first name ?"],
//     type:"text",
//     name:"firtName"
//   },
//   {
//     label: "Step 2",
//     fields: ["What is your last name ?"],
//     type:"text",
//     name:"lastName"

//   },
//   {
//     label: "Step 3",
//     fields: ["Enter your date of birth ?"],
//     type:"date",
//     name:"dob"
//   },
//   {
//     label: "Step 4",
//     fields: ["Do you have any children?"],
//     type:"children",
//     name:'isChildren'
//   },
//   {
//     label: "Step 5",
//     fields: ["OK - How many children do you have?"],
//     type:"childrenCount",
//      name:'childrenCount'
//   },
// ];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "350px",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const QuestionsModal = ({
  showQuestionsModalForm,
  setShowQuestionsModalForm,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    firstName:'',
    lastName:'',
    dob:'',
    isChildren:'no',
  });
  const [steps,setSteps]=useState([
    {
      label: "Step 1",
      fields: ["What is your first name ?"],
      type:"text",
      name:"firstName"
    },
    {
      label: "Step 2",
      fields: ["What is your last name ?"],
      type:"text",
      name:"lastName"
  
    },
    {
      label: "Step 3",
      fields: ["Enter your date of birth ?"],
      type:"date",
      name:"dob"
    },
    {
      label: "Step 4",
      fields: ["Do you have any children?"],
      type:"children",
      name:'isChildren'
    },
    
  ])
  const NAME_PATTERN=/^[A-Za-z]+(?:\s[A-Za-z]+)?$/
  const NUMBER_PATTERN=/^[0-9]+$/
  const [showError,setShowError]=useState(null)
  const dispatch= useDispatch();
  const navigate= useNavigate()
  //! dynamic stpes and form input handling block
  useEffect(()=>{
    if(formData["isChildren"]==="yes" && formData["childrenCount"]===undefined){
        setSteps([...steps, {
            label: "Step 5",
            fields: ["OK - How many children do you have?"],
            type:"childrenCount",
             name:'childrenCount'
          },])
    }
    else if(formData["isChildren"]==="no" && (formData["childrenCount"]!==undefined || formData["childrenCount"]===undefined)) {
        setSteps([
            {
              label: "Step 1",
              fields: ["What is your first name ?"],
              type:"text",
              name:"firstName"
            },
            {
              label: "Step 2",
              fields: ["What is your last name ?"],
              type:"text",
              name:"lastName"
          
            },
            {
              label: "Step 3",
              fields: ["Enter your date of birth ?"],
              type:"date",
              name:"dob"
            },
            {
              label: "Step 4",
              fields: ["Do you have any children?"],
              type:"children",
              name:'isChildren'
            },
            
          ])
          const formFields= formData;
          delete formData.childrenCount
          setFormData(formFields)
    }
  },[formData])

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setShowError(null)
  };


  const handleSubmit = () => {
    // TODO: Submit the form data
    alert('form submiited')
  };
  const onClose = () => {
    setShowQuestionsModalForm(false);
  };
  console.log('question form', formData);

  //*** Hanlde next steps function  */
  const handleNext = () => {
   //!checking empty field validation
    if(formData[steps[activeStep].name]===''){
        setShowError("This field is required.");
        return
    }
    //console.log('children count validation',steps[activeStep].type==="childrenCount" && formData[steps[activeStep].type]==='')
    if(steps[activeStep].type==="childrenCount" && formData[steps[activeStep].type]===''){
      setShowError("This field is required.");
      return
  }
    //! checking valid alphabates for name fields
    if(steps[activeStep].type==="text" && !NAME_PATTERN.test(formData[steps[activeStep].name]) ){
        setShowError("This field can only contain letters");
        return
    }
    //!checking valid number value for child count  field
    if(steps[activeStep].type==="childrenCount" && !NUMBER_PATTERN.test(formData[steps[activeStep].name]) ){
        setShowError("This field can only contain numeric values");
        return
    }
    //!navigating to pdf view component if all steps are completed
    if(activeStep === steps.length - 1){
        // handleSubmit();
        navigate('/preview')
        return
        }
        //! incrementing active step count
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //! each time after throwing error, making error state to null for further checks
    setShowError(null)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowError(null)
  };
  //!updating redux form state data 
  useEffect(()=>{
    dispatch(updateFormState(formData))
  },[formData])
  return (
    <>
      <Modal  open={showQuestionsModalForm} sx={{"& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop" :{backgroundColor:"rgb(243,243,254,.3)",outline:'none'},
    // opacity:0.8
    }}>
        
        <Paper elevation={4} sx={style} >
        {/* <Typography >Demo:Government form application</Typography> */}
            <Box sx={{ width: "100%", height: "20%" , overflow:'auto',mb:{xs:'1rem',sm:"1rem",lg:'0',xl:'0',md:'0'}}}>
            <Stepper activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            </Box>
          <Box sx={{ width: "100%", height: "40%" }}>
            <form>
              {steps[activeStep]?.fields?.map((field) => {
                 if(steps[activeStep].type==="text"){
                    return (
                        <TextField
                       
                        error={showError?true:false}
                        key={field}
                        name={steps[activeStep].name}
                        label={field}
                        value={formData[steps[activeStep].name] || ""}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                        helperText={showError && showError}
                        type={steps[activeStep].type}
                      />
                    )
                 }
                 else if(steps[activeStep].type==="date"){
                    return (
                        <TextField
                        error={showError?true:false}
                        helperText={showError && showError}
                        key={field}
                        name={"dob"}
                        label={field}
                        value={formData["dob"] || "01/01/2000"}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                        type={steps[activeStep].type}
                      />
                    )
                 }
                 else if(steps[activeStep].type==="children"){
                    return (
                        <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">{field} </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name={steps[activeStep].name}
                          onChange={handleFormChange}
                          value={formData[steps[activeStep].name] || ""}
                          row
                        >
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                         
                        </RadioGroup>
                      </FormControl>
                    )
                 }
                 else if(steps[activeStep].type==="childrenCount" && formData["isChildren"]==="yes"){
                    return (
                        <TextField
                        error={showError?true:false}
                        helperText={showError && showError}
                        key={field}
                        name={steps[activeStep].name}
                        label={field}
                        value={formData["childrenCount"] || ""}
                        onChange={handleFormChange}
                        fullWidth
                        margin="normal"
                        type={"text"}
                      />
                    )
                 }
                 
              })}
            </form>
           
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "20%",
              backgroundColor: "#9dc83b",
              display: "flex",
              alignContent: "center",
              color: "white",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight:'10px',
              justifyContent: {xs:'space-around',sm:'space-between',md:'space-between',lg:'space-between',xl:'space-between'},
            // flexDirection:{sm:'row',xs:"column",md:'row',lg:'row',xl:'row'}
             
            }}
          >
            <Stack
              direction={"row"}
            //  spacing={1}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                display: `${activeStep > 0 ? "" : "none"}`,
                // marginRight:'auto'
              }}
              onClick={handleBack}
            >
              <ArrowBackIcon />
              <Typography sx={{visibility:{xs:'hidden',sm:'visible',md:'visible',lg:'visible',xl:'visible'}}} variant="P">PREVIOUS</Typography>
            </Stack>

            <Stack
              direction={"row"}
              // gap={1}
              alignItems={"center"}
              sx={{ cursor: "pointer", marginLeft:'auto' }}
              onClick={handleNext}
            >
              <Typography variant="P" sx={{visibility:{xs:'hidden',sm:'visible',md:'visible',lg:'visible',xl:'visible'}}}>
                {" "}
                {activeStep === steps.length - 1 ? "SUBMIT" : "NEXT"}
              </Typography>
              <ArrowForwardIcon />
            </Stack>
          </Box>

          {/* <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button> */}
        </Paper>
      </Modal>
    </>
  );
};

export default QuestionsModal;
