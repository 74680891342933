
import { createSlice } from "@reduxjs/toolkit";
const initialState={
    form:{}
}
const formSlice= createSlice({
    name:"formSlice",
    initialState,
   reducers:{
    updateFormState:(state,action)=>{
        state.form= action.payload
    }
   }
})

export const {updateFormState}=formSlice.actions;
export default formSlice.reducer