import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PdfViewerComponent from '../components/PdfViewerComponent'
import GovForm from '../components/GovForm'

export default function AllRoutes() {
  return (
    <>
    <Routes>
      <Route path="/" element={<GovForm/>}/>
        <Route path="/preview" element={<PdfViewerComponent 	document={"document_new.pdf"}/>}/>
    </Routes>
    </>
  )
}
