import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function Welcome({ setShowWelcome }) {
  return (
    <>
      <Paper
        elevation={3}
        sx={{ width: "70%", height: "300px", margin: "auto", mt: "2rem" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#007ce2",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Box sx={{ width: "90px" }}>
            <svg
              data-name="Jotform Logo"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.4843 83.2998C29.0901 84.8565 27.9553 87.5235 25.6752 87.5235H11.4056C8.59017 87.5235 6.29932 85.3027 6.29932 82.5734V68.7401C6.29932 66.5297 9.05048 65.4297 10.6562 66.9863L27.4843 83.2998Z"
                fill="white"
              />
              <path
                d="M49.7895 84.3767C45.5656 80.1804 45.5656 73.3768 49.7895 69.1805L65.0334 54.0359C69.2572 49.8396 76.1055 49.8396 80.3293 54.0359C84.5532 58.2323 84.5532 65.0359 80.3293 69.2322L65.0854 84.3768C60.8616 88.5731 54.0133 88.5731 49.7895 84.3767Z"
                fill="white"
              />
              <path
                d="M9.51946 46.3227C5.2956 42.1264 5.2956 35.3228 9.51946 31.1264L35.1733 5.62186C39.3972 1.42553 46.2454 1.42552 50.4693 5.62186C54.6932 9.8182 54.6932 16.6218 50.4693 20.8181L24.8154 46.3227C20.5916 50.5191 13.7433 50.5191 9.51946 46.3227Z"
                fill="white"
              />
              <path
                d="M30.4902 64.5004C26.2663 60.3041 26.2663 53.5005 30.4902 49.3042L65.1532 14.867C69.3771 10.6706 76.2253 10.6706 80.4492 14.867C84.673 19.0633 84.673 25.8669 80.4492 30.0633L45.7861 64.5004C41.5623 68.6968 34.714 68.6968 30.4902 64.5004Z"
                fill="white"
              />
            </svg>
          </Box>
          <Typography variant="h4" sx={{ color: "white", fontSize: "2rem" }}>
            {" "}
            Welcome
          </Typography>
          <Typography variant="h6" sx={{ color: "white", fontSize: "1rem" }}>
            {" "}
            Click NEXT to start DEMO
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "20%",
            backgroundColor: "#9dc83b",
            display: "grid",
            placeContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowWelcome(false);
          }}
        >
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography variant="h6">NEXT</Typography>
            <ArrowForwardIcon />
          </Stack>
        </Box>
      </Paper>
    </>
  );
}
