import { CssBaseline } from '@mui/material';
import AllRoutes from './Routes/AllRoutes';

function App() {
	return (
		<>
		<CssBaseline/>
		<div className="App">
			<div className="PDF-viewer">
				<AllRoutes/>
			{/* <PdfViewerComponent
				document={"document.pdf"}
			/> */}
			{/* <DownloadPdf pdfUrl={"document.pdf"}/> */}
			</div>
		</div>
		</>
	);
}

export default App;