import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function PdfViewerComponent(props) {
  const { form } = useSelector((store) => store.formReducer);
  const { firstName, lastName, dob, isChildren, childrenCount } = form;
  console.log("redux form state", form);
  const containerRef = useRef(null);

  //Insert the form field values into Instant JSON.
  const instantJSON = {
    format: "https://pspdfkit.com/instant-json/v1",
    formFieldValues: [
      {
        v: 1,
        type: "pspdfkit/form-field-value",
        name: "firstname",
        value: firstName,
      },
      {
        v: 1,
        type: "pspdfkit/form-field-value",
        name: "lastname",
        value: lastName,
      },
      { v: 1, type: "pspdfkit/form-field-value", name: "dob", value: dob },
      {
        v: 1,
        type: "pspdfkit/form-field-value",
        name: "Option-yes",
        value: isChildren === "yes" ? "Yes" : "No",
      },
      {
        v: 1,
        type: "pspdfkit/form-field-value",
        name: "numberofchildren",
        value: childrenCount,
      },
      {
        v: 1,
        type: "pspdfkit/form-field-value",
        name: "claim",
        value: `${
          +childrenCount *
            process.env.REACT_APP_APPLICANT_CHILD_CLAIMED_AMMOUNT +
            +process.env.REACT_APP_APPLICANT_CLAIMED_AMMOUNT || (+process.env.REACT_APP_APPLICANT_CLAIMED_AMMOUNT)
        }`,
      },
    ],
  };

  useEffect(() => {
    const container = containerRef.current;
    let instance, PSPDFKit;

    (async function () {
      PSPDFKit = await import("pspdfkit");
      PSPDFKit.unload(container);

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        textSelectionEnabled: true,
        preloadPages: true,
        instantRendering: false,

        //!enable required menus
        toolbarItems: [
          ...PSPDFKit.defaultToolbarItems.filter(
            (cur) =>
              cur.type !== "image" &&
              cur.type !== "stamp" &&
              cur.type !== "annotate" &&
              cur.type !== "ink" &&
              cur.type !== "highlighter" &&
              cur.type !== "text-highlighter" &&
              cur.type !== "ink-eraser" &&
              cur.type !== "signature" &&
              cur.type !== "note" &&
              cur.type !== "text" &&
              cur.type !== "line" &&
              cur.type !== "link" &&
              cur.type !== "arrow" &&
              cur.type !== "rectangle" &&
              cur.type !== "ellipse" &&
              cur.type !== "polygon" &&
              cur.type !== "cloudy-polygon" &&
              cur.type !== "polyline" &&
              cur.type !== "document-editor" &&
              cur.type !== "document-crop" &&
              cur.type !== "multi-annotations-selection" &&
              cur.type !== "pan" &&
              cur.type !== "highlighter" &&
              cur.type !== "sidebar-document-outline" &&
              cur.type !== "sidebar-annotations" &&
              cur.type !== "sidebar-bookmarks"
          ),
          // ...PSPDFKit.defaultToolbarItems,
          // {
          //   type: "form-creator"
          // },
          // {
          // 	type: "content-editor"
          //   }
        ],
        // theme: PSPDFKit.Theme.DARK,
        instantJSON,
        styleSheets: ["../index.css"],
        // headless:true,
        // isEditableAnnotation: function(ann) {
        // 	return !(ann instanceof PSPDFKit.Annotations.WidgetAnnotation)
        //   },
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      });
      //! hiding the default text selection tooltip
      instance.setInlineTextSelectionToolbarItems(
        ({ defaultItems, hasDesktopLayout }, selection) => {
          console.log(selection);
          // Add custom button only on desktop.
          if (hasDesktopLayout) {
            return [];
          }
          return defaultItems;
        }
      );
      //!! download pdf
      // instance.exportPDF().then(function(buffer) {
      // 	const blob = new Blob([buffer], { type: "application/pdf" });
      // 	const url = URL.createObjectURL(blob);
      // 	const link = document.createElement("a");
      // 	link.href = url;
      // 	link.download = "download.pdf";
      // 	document.body.appendChild(link);
      // 	link.click();
      // 	document.body.removeChild(link);
      // 	URL.revokeObjectURL(url);
      //   });

      // console.log('default toolbar',PSPDFKit.defaultToolbarItems)
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, []);

  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
}
