import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function QuestionFormStart({
  setShowQuestionsModalForm,
  showQuestionsModalForm,
}) {
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "70%",
          height: "200px",
          margin: "auto",
          mt: "2rem",
          display: showQuestionsModalForm ? "none" : "block",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#007ce2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            gap: "10px",
            paddingLeft: "10px",
          }}
        >
          <Typography variant="h5" sx={{ color: "white" }}>
            {" "}
            Demo : Government Support Form Application
          </Typography>
          <Typography variant="h6" sx={{ color: "white", fontSize: "1rem" }}>
            {" "}
            5 Questions
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "20%",
            backgroundColor: "#9dc83b",
            display: "grid",
            placeContent: "center",
            color: "white",
          }}
          onClick={() => {
            setShowQuestionsModalForm(true);
          }}
        >
          <Stack
            direction={"row"}
            gap={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h6">WEITER</Typography>
            <ArrowForwardIcon />
          </Stack>
        </Box>
      </Paper>
    </>
  );
}
